// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Breadcrumbnavigation from "../../blocks/breadcrumbnavigation/src/Breadcrumbnavigation";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Cfmaxmindintegration from "../../blocks/cfmaxmindintegration/src/Cfmaxmindintegration";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import Customform3 from "../../blocks/customform3/src/Customform3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Savedcards from "../../blocks/savedcards/src/Savedcards";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Projectportfolio from "../../blocks/projectportfolio/src/Projectportfolio";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Paypalintegration2 from "../../blocks/paypalintegration2/src/Paypalintegration2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Chatbot from "../../blocks/chatbot/src/Chatbot";
import Sorting from "../../blocks/sorting/src/Sorting";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import RequestManagement from "../../blocks/requestmanagement/src/RequestManagement";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import Videos from "../../blocks/videos/src/Videos";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import Videomanagement from "../../blocks/videomanagement/src/Videomanagement";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Analytics from "../../blocks/analytics/src/Analytics";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";



const routeMap = {
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Breadcrumbnavigation:{
 component:Breadcrumbnavigation,
path:"/Breadcrumbnavigation"},
EducationalUserProfile:{
 component:EducationalUserProfile,
path:"/EducationalUserProfile"},
Cfmaxmindintegration:{
 component:Cfmaxmindintegration,
path:"/Cfmaxmindintegration"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
Customform3:{
 component:Customform3,
path:"/Customform3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Savedcards:{
 component:Savedcards,
path:"/Savedcards"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Projectportfolio:{
 component:Projectportfolio,
path:"/Projectportfolio"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Paypalintegration2:{
 component:Paypalintegration2,
path:"/Paypalintegration2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
UserProfileBasicBlock:{
 component:UserProfileBasicBlock,
path:"/UserProfileBasicBlock"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Chatbot:{
 component:Chatbot,
path:"/Chatbot"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
AuctionBidding:{
 component:AuctionBidding,
path:"/AuctionBidding"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
RequestManagement:{
 component:RequestManagement,
path:"/RequestManagement"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
Videos:{
 component:Videos,
path:"/Videos"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Dashboard:{
 component:Dashboard,
path:"/Dashboard"},
Videomanagement:{
 component:Videomanagement,
path:"/Videomanagement"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},

  Home: {
component:OrderManagement,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
